import React, { useState, useEffect } from "react";
import * as Ant from "antd";

import * as Survey from "../../Contexts/SurveyContext";

const EditCategoryModal = ({
  visible,
  setShowEditCategoryModal,
  selectedTemplate,
  fetchAllTemplate,
}) => {
  const {
    TemplateId: id = null,
    TemplateCategory: orgTemplateCategoryId = null,
  } = selectedTemplate || {};

  const [templateCategory, setTemplateCategory] = useState([]);
  const [templateCategoryId, setTemplateCategoryId] = useState();

  // 取得範本類別列表
  async function fetchTemplateCategory() {
    const response = await Survey.Actions.fetchTemplateCategory();
    if (
      response.code == "200" &&
      response.data &&
      Array.isArray(response.data)
    ) {
      setTemplateCategory(response.data);
    }
  }

  async function handleOk() {
    await Survey.Actions.updateTemplate(id, {
      TemplateCategory: templateCategoryId,
    });
    setShowEditCategoryModal(false);
    fetchAllTemplate();
    setTemplateCategoryId(orgTemplateCategoryId);
  }

  const handleCancel = () => {
    setShowEditCategoryModal(false);
    setTemplateCategoryId(orgTemplateCategoryId);
  };

  useEffect(() => {
    fetchTemplateCategory();
  }, []);

  useEffect(() => {
    setTemplateCategoryId(orgTemplateCategoryId);
  }, [orgTemplateCategoryId]);

  return (
    <Ant.Modal
      className="custom-modal"
      title="移動問卷範本類別"
      visible={visible}
      bodyStyle={{ padding: "32px 36px" }}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="確定"
      cancelText="取消"
      centered
      width={600}
      destroyOnClose
    >
      <div style={{ margin: "0 0 8px 0" }}>範本分類</div>
      <Ant.Select
        style={{ width: "200px" }}
        placeholder="問卷範本分類"
        defaultValue={orgTemplateCategoryId}
        value={templateCategoryId}
        onChange={e => setTemplateCategoryId(e)}
      >
        {templateCategory.map((itm, idx) => (
          <Ant.Select.Option value={itm.Code} key={idx}>
            {itm.Name}
          </Ant.Select.Option>
        ))}
      </Ant.Select>
    </Ant.Modal>
  );
};

export default EditCategoryModal;
