import React, { useState } from "react";
import * as Ant from "antd";

import * as Survey from "../../Contexts/SurveyContext";

const EditTitleModal = props => {
  const { Subject: orgSubject = "", TemplateId: id = null } =
    props.selectedTemplate || {};
  const { visible, setShowEditTitleModal, fetchAllTemplate } = props;
  const [subject, setSubject] = useState();

  async function onOk() {
    await Survey.Actions.updateTemplate(id, { Title: subject });
    setSubject();
    setShowEditTitleModal(false);
    fetchAllTemplate();
  }

  async function onCancel() {
    setSubject();
    setShowEditTitleModal(false);
  }

  return (
    <Ant.Modal
      className="custom-modal"
      title="問卷名稱"
      width={600}
      centered
      visible={visible}
      bodyStyle={{ padding: "32px 36px" }}
      onCancel={() => onCancel()}
      onOk={() => onOk()}
      okText="確定"
      cancelText="取消"
    >
      <Ant.Input
        placeholder="請輸入問卷名稱"
        value={subject || orgSubject}
        onChange={e => setSubject(e.target.value)}
      />
    </Ant.Modal>
  );
};

export default EditTitleModal;
