import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import * as Ant from "antd";

import { Color, FontSize } from "../Widget";
import * as Survey from "../../Contexts/SurveyContext";
import { navbarHeight } from "../Navbar";
import EditTitleModal from "./EditTitleModal";
import EditCategoryModal from "./EditCategoryModal";
import ImportTemplateModal from "./ImportTemplateModal";
import TempelateCard from "./TempelateCard";

const TemplatesPage = props => {
  const { profile, appActions, popup } = props;
  const { fromSurvey } = props;
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState(null);
  // 原始自有範本資料，搜尋用
  const [orgData, setOrgData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [surveyList, setSurveyList] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [updater, setUpdater] = useState(0);
  const [templateCategory, setTemplateCategory] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("customization");
  const [publicTemplate, setPublicTemplate] = useState([]);
  const [publicTemplateLoading, setPublicTemplateLoading] = useState(true);
  // 原始共有範本資料，搜尋用
  const [orgPublicTemplate, setOrgPublicTemplate] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showImportTemplateModal, setShowImportTemplateModal] = useState(false);
  const [showLess, setShowLess] = useState({});

  // 取得問卷列表
  // async function fetchSurveyList() {
  //   try {
  //     let list = await Survey.Actions.fetchMySurveys();
  //     setSurveyList(list);
  //   } catch (err) {
  //     setSurveyList([]);
  //   }
  // }

  // 取得自訂範本
  async function fetchData() {
    setDataLoading(true);
    try {
      let list = await Survey.Actions.fetchTemplateList();
      if (list.code && list.code == "406") {
        appActions.logout();
        console.debug("navigate to root");
        navigate("/");
      } else {
        list.code && setData(list.data);
        list.code && setOrgData(list.data);
      }
    } catch (err) {
      setData(null);
      setOrgData(null);
    }
    setDataLoading(false);
  }

  // 取得範本類別列表
  async function fetchTemplateCategory() {
    setPublicTemplateLoading(true);
    const response = await Survey.Actions.fetchTemplateCategory();
    if (
      response.code == "200" &&
      response.data &&
      Array.isArray(response.data)
    ) {
      setTemplateCategory(response.data);
    }
    setPublicTemplateLoading(false);
  }

  // 取得範本類別列表對應的範本
  async function fetchTemplateByCategory() {
    if (templateCategory.length > 0) {
      const req = templateCategory.map(item =>
        Survey.Actions.fetchTemplateByCategory(item.Code)
      );
      const res = await Promise.all(req);
      const data = res.map(item => item.data);
      setPublicTemplate(data);
      setOrgPublicTemplate(data);
    }
  }

  async function fetchAllTemplate() {
    fetchData();
    fetchTemplateByCategory();
  }

  // 滾動到所選之類別
  const choiceCategory = id => {
    const ele = document.getElementById(id);
    if (ele) {
      document.getElementById("content-scroll").scrollTo({
        top: ele.offsetTop,
        behavior: "smooth",
      });
    }
    setSelectedMenu(id);
  };

  // 搜尋功能
  const searchByName = value => {
    setSearchName(value);
    if (value) {
      const filterData = orgData?.filter(
        itm => itm.Subject.indexOf(value) != -1
      );
      const filterPublicTemplate = orgPublicTemplate.map(itm =>
        itm ? itm.filter(filter => filter.Subject.indexOf(value) != -1) : null
      );
      setData(filterData);
      setPublicTemplate(filterPublicTemplate);
    } else {
      // 清空輸入框時，回復資料
      setData(orgData);
      setPublicTemplate(orgPublicTemplate);
    }
  };

  const Loading = () => (
    <div
      style={{
        height: "173px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Ant.Spin />
    </div>
  );

  const renderShowMoreOrLess = name => {
    const handleClickShowMoreOrLess = () => {
      setShowLess(state => ({ ...state, [name]: !state[name] }));
    };

    return (
      <div className="show-more-or-less-wrapper">
        <div className="show-more-or-less" onClick={handleClickShowMoreOrLess}>
          {showLess[name] ? "顯示更少...." : "顯示更多...."}
        </div>
      </div>
    );
  };

  const getItemPerRows = () => {
    if (popup) return 3;
    if (window.innerWidth < 1680) return 4;
    return 5;
  };

  // 首次載入自有範本、分類
  useEffect(() => {
    fetchTemplateCategory();
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAllTemplate();
  }, [templateCategory]);

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  return (
    <TempWrapper className="template-wrapper" popup={popup}>
      <div className="side-menu-area">
        {popup && <div className="modal-title">問卷範本</div>}
        <div className="title-area">
          <div
            className={`menu-title content-subtitle ${
              selectedMenu === `customization` ? "selected" : ""
            }`}
            onClick={() => choiceCategory(`customization`)}
          >
            自訂範本
          </div>
        </div>
        <div className="divider"></div>
        <div className="content-area">
          <div className="content-title">範本</div>
          <div className="content">
            {templateCategory.map(item => (
              <div
                key={item.Code}
                className={`item menu-title ${
                  selectedMenu === `category-${item.Code}` ? "selected" : ""
                }`}
                onClick={() => choiceCategory(`category-${item.Code}`)}
              >
                {item.Name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="main-area">
        <div className="search-area">
          <Ant.Input.Search
            type="text"
            placeholder="範本名稱"
            className="search-bar"
            onChange={e => searchByName(e.target.value)}
          />
        </div>
        <div id="content-scroll" className="content-scroll">
          <div className="content-area">
            <div id="customization" className="customization">
              <div className="content-title">自訂範本</div>
              {dataLoading ? (
                <Loading />
              ) : data?.length > 0 ? (
                <>
                  <div className="item">
                    {data.map((item, idx) => (
                      <>
                        <TempelateCard
                          key={item.TemplateId}
                          item={item}
                          setUpdater={setUpdater}
                          updater={updater}
                          width={
                            popup
                              ? "calc((100% - 24*3px)/3)"
                              : "calc((100% - 24*3px)/4)"
                          }
                          setSelectedTemplate={setSelectedTemplate}
                          setShowEditTitleModal={setShowEditTitleModal}
                          setShowEditCategoryModal={setShowEditCategoryModal}
                          setShowImportTemplateModal={
                            setShowImportTemplateModal
                          }
                          popup={popup}
                          type="customization"
                          profile={profile}
                          fetchAllTemplate={fetchAllTemplate}
                          isHidden={
                            idx + 1 > getItemPerRows() * 2 &&
                            !showLess["customization"]
                          }
                          fromSurvey={fromSurvey}
                        />
                      </>
                    ))}
                  </div>
                  {data?.length > getItemPerRows() * 2 &&
                    renderShowMoreOrLess("customization")}
                </>
              ) : (
                <Ant.Empty />
              )}
            </div>
            <div className="divider"></div>
            <div className="public">
              <div className="content-title">範本</div>
              <div className="content">
                {templateCategory.map((itm, idx) => {
                  const publicData = publicTemplate[idx];
                  return (
                    <div
                      key={`category-${itm.Code}`}
                      className="category-wrapper"
                    >
                      <div
                        id={`category-${itm.Code}`}
                        className="content-subtitle"
                      >
                        {itm.Name}
                      </div>
                      {publicTemplateLoading ? (
                        <Loading />
                      ) : publicData?.length > 0 ? (
                        <>
                          <div className="item">
                            {publicData.map((item, idx) => (
                              <TempelateCard
                                key={item.TemplateId}
                                item={item}
                                setUpdater={setUpdater}
                                updater={updater}
                                width={
                                  popup
                                    ? "calc((100% - 24*3px)/3)"
                                    : "calc((100% - 24*3px)/4)"
                                }
                                setSelectedTemplate={setSelectedTemplate}
                                setShowEditTitleModal={setShowEditTitleModal}
                                setShowEditCategoryModal={
                                  setShowEditCategoryModal
                                }
                                setShowImportTemplateModal={
                                  setShowImportTemplateModal
                                }
                                popup={popup}
                                type="public"
                                profile={profile}
                                fetchAllTemplate={fetchAllTemplate}
                                isHidden={
                                  idx + 1 > getItemPerRows() * 2 &&
                                  !showLess[`category-${itm.Code}`]
                                }
                                fromSurvey={fromSurvey}
                              />
                            ))}
                          </div>
                          {publicData?.length > getItemPerRows() * 2 &&
                            renderShowMoreOrLess(`category-${itm.Code}`)}
                        </>
                      ) : (
                        <Ant.Empty />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditTitleModal
        visible={showEditTitleModal}
        setShowEditTitleModal={setShowEditTitleModal}
        selectedTemplate={selectedTemplate}
        fetchAllTemplate={fetchAllTemplate}
      />
      <EditCategoryModal
        visible={showEditCategoryModal}
        setShowEditCategoryModal={setShowEditCategoryModal}
        selectedTemplate={selectedTemplate}
        fetchAllTemplate={fetchAllTemplate}
      />
      <ImportTemplateModal
        visible={showImportTemplateModal}
        setShowImportTemplateModal={setShowImportTemplateModal}
        selectedTemplate={selectedTemplate}
        fetchAllTemplate={fetchAllTemplate}
      />
    </TempWrapper>
  );
};

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1;
  min-height: calc(100vh - ${navbarHeight}px);

  .side-menu-area {
    width: 240px;
    max-width: 240px;
    min-width: 240px;
    font-size: ${FontSize.title}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    color: ${Color.MPurple_6};
    display: flex;
    flex-direction: column;

    .modal-title {
      margin: 32px 0 0 39px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      font-weight: 500;
    }

    .title-area {
      height: 48px;
      padding: 10px 16px 10px 36px;
      margin: 36px 0 32px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .content-area {
      padding: 18px 0 0 0;
      color: ${Color.MPurple_10_60};
      flex: 1;

      .content-title {
        height: 48px;
        line-height: 48px;
        padding-left: 36px;
        font-size: 20px;
      }

      .menu-title {
        font-size: 16px;
        padding: 12px 16px 12px 59px;
        height: 48px;
        display: flex;
        align-items: center;
      }
    }
  }

  .main-area {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
    }

    .search-area {
      margin: 40px 36px 36px;

      .search-bar {
        min-width: 564px;
        width: 50%;
        height: 40px;

        input {
          font-size: 16px;
        }

        .ant-input-suffix {
          margin: auto 0 auto 4px;
        }
      }
    }

    .content-scroll {
      position: relative;
      overflow: scroll;
      height: calc(100vh - 168px);
      padding: 0 36px 0;
    }

    .content-area {
      width: 100%;

      .customization {
        margin-bottom: 30px;
      }

      .public {
        margin-top: 32px;

        .content-subtitle {
          margin-bottom: 16px;
        }
      }
    }

    .content-title {
      margin-bottom: 24px;
      font-size: ${FontSize.largeTitle}px;
      font-weight: 500;
      letter-spacing: normal;
    }
    .content-subtitle {
      font-size: ${FontSize.title}px;
      letter-spacing: normal;
    }
  }

  .divider {
    height: 0.5px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .menu-title {
    padding: 10px 16px 10px 36px;
    color: ${Color.MPurple_10_60};
    line-height: 1;
    border-left-color: rgb(0, 0, 0, 0);
    border-left-width: 2px;
    border-left-style: solid;
    padding: 0;
    padding-left: 10px;
    cursor: pointer;

    &:hover {
      color: ${Color.MPurple_5};
    }

    &.selected {
      color: ${Color.MPurple_6};
      border-left-color: ${Color.MPurple_6};
    }
  }

  .category-wrapper {
    margin-bottom: 32px;
  }

  .show-more-or-less-wrapper {
    text-align: right;

    .show-more-or-less {
      display: inline-block;
      font-size: 16px;
      color: ${Color.MPurple_6};
      text-align: right;
      height: 24px;
      line-height: 24px;
      cursor: pointer;
    }
  }
`;

export default TemplatesPage;
